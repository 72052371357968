import * as React from "react"
// import IconJava from "./iconJava"
// import IconBedrock from "./iconBedrock"
import { Link } from "gatsby"
// import IconBookshelf from "./iconBookshelf"

function CommandInfo(props) {
  return (
    <div className="flex justify-between">
      <Link to={`/command/${props.command.name}`}>
        <h2 className="text-2xl font-semibold command-name" id={props.command.name}>
          /{props.command.name}
        </h2>
      </Link>
      {/* {props.command.editions && <div className="text-3xl flex">
        {props.command.editions.java && <div title="Minecraft Java Edition" className="mr-2"><IconJava /></div>}
        {props.command.editions.bedrock && <div title="Minecraft Bedrock Edition" className="mr-2"><IconBedrock /></div>}
        {props.command.editions.education && <div title="Minecraft Education Edition" className="mr-2"><IconBookshelf /></div>}
      </div>} */}
    </div>
  )
}

export default CommandInfo
