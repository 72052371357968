import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

function IconJava(props) {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "java.png" }) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.image.childImageSharp.fixed} />

  // return (
  //   <svg viewBox="0 0 48 48" width="1em" height="1em" {...props}>
  //     <path
  //       fill="#F44336"
  //       d="M23.65 24.898c-.998-1.609-1.722-2.943-2.725-5.455C19.229 15.2 31.24 11.366 26.37 3.999c2.111 5.089-7.577 8.235-8.477 12.473-.823 3.898 5.752 8.426 5.757 8.426z"
  //     />
  //     <path
  //       fill="#F44336"
  //       d="M23.878 17.27c-.192 2.516 2.229 3.857 2.299 5.695.056 1.496-1.447 2.743-1.447 2.743s2.728-.536 3.579-2.818c.945-2.534-1.834-4.269-1.548-6.298.267-1.938 6.031-5.543 6.031-5.543s-8.481.562-8.914 6.221z"
  //     />
  //     <g fill="#1565C0">
  //       <path d="M32.084 25.055c1.754-.394 3.233.723 3.233 2.01 0 2.901-4.021 5.643-4.021 5.643s6.225-.742 6.225-5.505c0-3.15-3.057-3.937-5.437-2.148zm-2.955 2.34s1.941-1.383 2.458-1.902c-4.763 1.011-15.638 1.147-15.638.269 0-.809 3.507-1.638 3.507-1.638s-7.773-.112-7.773 2.181c0 2.39 10.175 2.561 17.446 1.09z" />
  //       <path d="M27.935 29.571c-4.509 1.499-12.814 1.02-10.354-.993-1.198 0-2.974.963-2.974 1.889 0 1.857 8.982 3.291 15.63.572l-2.302-1.468z" />
  //       <path d="M18.686 32.739c-1.636 0-2.695 1.054-2.695 1.822 0 2.391 9.76 2.632 13.627.205l-2.458-1.632c-2.889 1.27-10.146 1.445-8.474-.395z" />
  //       <path d="M36.281 36.632c0-.936-1.055-1.377-1.433-1.588 2.228 5.373-22.317 4.956-22.317 1.784 0-.721 1.807-1.427 3.477-1.093l-1.42-.839C11.26 34.374 9 35.837 9 37.017c0 5.503 27.281 5.238 27.281-.385z" />
  //       <path d="M39 38.604c-4.146 4.095-14.659 5.587-25.231 3.057C24.341 46.164 38.95 43.628 39 38.604z" />
  //     </g>
  //   </svg>
  // )
}

export default IconJava
