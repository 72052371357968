import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

function IconBookshelf(props) {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "bookshelf.png" }) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.image.childImageSharp.fixed} />

  // return (
  //   <svg baseProfile="full" height="1em" width="1em" viewBox="0 0 192 224" {...props}>
  //     <path d="M0 56v112l96 56V112z" fill="#96794e" />
  //     <path d="M18 66.5v7l6 3.5v-7z" fill="#907348" />
  //     <path
  //       d="M42 80.5v7l6 3.5v-7zM48 84v7l6 3.5v-7zM54 87.5v7l6 3.5v-7z"
  //       fill="#7f693d"
  //     />
  //     <path d="M60 91v7l6 3.5v-7z" fill="#5c4b2d" />
  //     <path d="M90 108.5v7l6 3.5v-7z" fill="#7f693d" />
  //     <path d="M6 66.5v7l6 3.5v-7z" fill="#231b0f" />
  //     <path
  //       d="M12 70v7l6 3.5v-7zM18 73.5v7l6 3.5v-7zM24 77v7l6 3.5v-7zM30 80.5v7l6 3.5v-7z"
  //       fill="#1f180d"
  //     />
  //     <path d="M36 84v7l6 3.5v-7z" fill="#5d710d" />
  //     <path d="M42 87.5v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M48 91v7l6 3.5v-7zM54 94.5v7l6 3.5v-7z" fill="#231b0f" />
  //     <path
  //       d="M60 98v7l6 3.5v-7zM66 101.5v7l6 3.5v-7zM72 105v7l6 3.5v-7zM78 108.5v7l6 3.5v-7z"
  //       fill="#1f180d"
  //     />
  //     <path d="M84 112v7l6 3.5v-7z" fill="#231b0f" />
  //     <path d="M90 115.5v7l6 3.5v-7z" fill="#7f693d" />
  //     <path d="M6 73.5v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M12 77v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M18 80.5v7l6 3.5v-7z" fill="#231b0f" />
  //     <path d="M24 84v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M30 87.5v7l6 3.5v-7z" fill="#213e61" />
  //     <path d="M36 91v7l6 3.5v-7z" fill="#5d710d" />
  //     <path d="M42 94.5v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path
  //       d="M48 98v7l6 3.5v-7zM54 101.5v7l6 3.5v-7zM60 105v7l6 3.5v-7z"
  //       fill="#2b2110"
  //     />
  //     <path d="M66 108.5v7l6 3.5v-7z" fill="#5d710d" />
  //     <path d="M72 112v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M78 115.5v7l6 3.5v-7z" fill="#322413" />
  //     <path d="M84 119v7l6 3.5v-7z" fill="#342714" />
  //     <path d="M90 122.5v7l6 3.5v-7z" fill="#7f693d" />
  //     <path d="M0 77v7l6 3.5v-7z" fill="#544328" />
  //     <path d="M6 80.5v7l6 3.5v-7z" fill="#909090" />
  //     <path d="M12 84v7l6 3.5v-7z" fill="#6d6d6d" />
  //     <path d="M18 87.5v7l6 3.5v-7z" fill="#0d7155" />
  //     <path d="M24 91v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M30 94.5v7l6 3.5v-7z" fill="#213e61" />
  //     <path d="M36 98v7l6 3.5v-7z" fill="#5d710d" />
  //     <path d="M42 101.5v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M48 105v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M54 108.5v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M60 112v7l6 3.5v-7z" fill="#2b2110" />
  //     <path d="M66 115.5v7l6 3.5v-7z" fill="#5d710d" />
  //     <path d="M72 119v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M78 122.5v7l6 3.5v-7z" fill="#0d7155" />
  //     <path d="M84 126v7l6 3.5v-7z" fill="#095540" />
  //     <path d="M90 129.5v7l6 3.5v-7z" fill="#5c4b2d" />
  //     <path d="M6 87.5v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M12 91v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M18 94.5v7l6 3.5v-7z" fill="#0d7155" />
  //     <path d="M24 98v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M30 101.5v7l6 3.5v-7z" fill="#213e61" />
  //     <path d="M36 105v7l6 3.5v-7z" fill="#3a3a3a" />
  //     <path d="M42 108.5v7l6 3.5v-7z" fill="#323232" />
  //     <path d="M48 112v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M54 115.5v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M60 119v7l6 3.5v-7z" fill="#322413" />
  //     <path d="M66 122.5v7l6 3.5v-7z" fill="#5d710d" />
  //     <path d="M72 126v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M78 129.5v7l6 3.5v-7z" fill="#909090" />
  //     <path d="M84 133v7l6 3.5v-7z" fill="#6d6d6d" />
  //     <path d="M0 91v7l6 3.5v-7z" fill="#7f693d" />
  //     <path d="M6 94.5v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M12 98v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M18 101.5v7l6 3.5v-7z" fill="#0d7155" />
  //     <path d="M24 105v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M30 108.5v7l6 3.5v-7z" fill="#213e61" />
  //     <path d="M36 112v7l6 3.5v-7z" fill="#3a3a3a" />
  //     <path d="M42 115.5v7l6 3.5v-7z" fill="#323232" />
  //     <path d="M48 119v7l6 3.5v-7z" fill="#342714" />
  //     <path d="M54 122.5v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M60 126v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M66 129.5v7l6 3.5v-7z" fill="#5d710d" />
  //     <path d="M72 133v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M78 136.5v7l6 3.5v-7z" fill="#0d7155" />
  //     <path d="M84 140v7l6 3.5v-7z" fill="#095540" />
  //     <path d="M90 143.5v7l6 3.5v-7z" fill="#7f693d" />
  //     <path d="M6 101.5v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M12 105v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M18 108.5v7l6 3.5v-7z" fill="#0d7155" />
  //     <path d="M24 112v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M30 115.5v7l6 3.5v-7z" fill="#213e61" />
  //     <path d="M36 119v7l6 3.5v-7z" fill="#5d710d" />
  //     <path d="M42 122.5v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M48 126v7l6 3.5v-7z" fill="#322413" />
  //     <path d="M54 129.5v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M60 133v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M66 136.5v7l6 3.5v-7z" fill="#5d710d" />
  //     <path d="M72 140v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M78 143.5v7l6 3.5v-7z" fill="#0d7155" />
  //     <path d="M84 147v7l6 3.5v-7z" fill="#095540" />
  //     <path d="M0 105v7l6 3.5v-7zM6 108.5v7l6 3.5v-7z" fill="#5c4b2d" />
  //     <path
  //       d="M12 112v7l6 3.5v-7zM18 115.5v7l6 3.5v-7zM24 119v7l6 3.5v-7zM30 122.5v7l6 3.5v-7zM36 126v7l6 3.5v-7z"
  //       fill="#544328"
  //     />
  //     <path
  //       d="M42 129.5v7l6 3.5v-7zM48 133v7l6 3.5v-7zM54 136.5v7l6 3.5v-7z"
  //       fill="#5c4b2d"
  //     />
  //     <path
  //       d="M60 140v7l6 3.5v-7zM66 143.5v7l6 3.5v-7zM72 147v7l6 3.5v-7zM78 150.5v7l6 3.5v-7z"
  //       fill="#544328"
  //     />
  //     <path d="M84 154v7l6 3.5v-7zM90 157.5v7l6 3.5v-7z" fill="#5c4b2d" />
  //     <path
  //       d="M6 115.5v7l6 3.5v-7zM12 119v7l6 3.5v-7zM18 122.5v7l6 3.5v-7zM24 126v7l6 3.5v-7z"
  //       fill="#7f693d"
  //     />
  //     <path d="M36 133v7l6 3.5v-7zM42 136.5v7l6 3.5v-7z" fill="#907348" />
  //     <path
  //       d="M48 140v7l6 3.5v-7zM54 143.5v7l6 3.5v-7zM60 147v7l6 3.5v-7z"
  //       fill="#7f693d"
  //     />
  //     <path d="M66 150.5v7l6 3.5v-7z" fill="#5c4b2d" />
  //     <path
  //       d="M78 157.5v7l6 3.5v-7zM84 161v7l6 3.5v-7zM90 164.5v7l6 3.5v-7zM0 119v7l6 3.5v-7z"
  //       fill="#7f693d"
  //     />
  //     <path
  //       d="M6 122.5v7l6 3.5v-7zM12 126v7l6 3.5v-7zM18 129.5v7l6 3.5v-7zM24 133v7l6 3.5v-7zM30 136.5v7l6 3.5v-7zM36 140v7l6 3.5v-7z"
  //       fill="#1f180d"
  //     />
  //     <path
  //       d="M42 143.5v7l6 3.5v-7zM48 147v7l6 3.5v-7zM54 150.5v7l6 3.5v-7z"
  //       fill="#231b0f"
  //     />
  //     <path d="M60 154v7l6 3.5v-7zM66 157.5v7l6 3.5v-7z" fill="#1f180d" />
  //     <path d="M72 161v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M78 164.5v7l6 3.5v-7z" fill="#213e61" />
  //     <path d="M84 168v7l6 3.5v-7z" fill="#231b0f" />
  //     <path d="M90 171.5v7l6 3.5v-7z" fill="#7f693d" />
  //     <path d="M6 129.5v7l6 3.5v-7z" fill="#1f180d" />
  //     <path d="M12 133v7l6 3.5v-7zM18 136.5v7l6 3.5v-7z" fill="#2b2110" />
  //     <path d="M24 140v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M30 143.5v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M36 147v7l6 3.5v-7zM42 150.5v7l6 3.5v-7z" fill="#322413" />
  //     <path d="M48 154v7l6 3.5v-7z" fill="#2b2110" />
  //     <path d="M54 157.5v7l6 3.5v-7z" fill="#867c08" />
  //     <path d="M60 161v7l6 3.5v-7z" fill="#6d6406" />
  //     <path d="M66 164.5v7l6 3.5v-7z" fill="#231b0f" />
  //     <path d="M72 168v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M78 171.5v7l6 3.5v-7z" fill="#213e61" />
  //     <path d="M84 175v7l6 3.5v-7z" fill="#2b2110" />
  //     <path d="M90 178.5v7l6 3.5v-7z" fill="#7f693d" />
  //     <path d="M0 133v7l6 3.5v-7z" fill="#5c4b2d" />
  //     <path d="M6 136.5v7l6 3.5v-7z" fill="#6c2b06" />
  //     <path d="M12 140v7l6 3.5v-7z" fill="#5f2605" />
  //     <path d="M18 143.5v7l6 3.5v-7z" fill="#4f2004" />
  //     <path d="M24 147v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M30 150.5v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M36 154v7l6 3.5v-7z" fill="#2b2110" />
  //     <path d="M42 157.5v7l6 3.5v-7z" fill="#342714" />
  //     <path d="M48 161v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M54 164.5v7l6 3.5v-7z" fill="#909090" />
  //     <path d="M60 168v7l6 3.5v-7z" fill="#6d6d6d" />
  //     <path d="M66 171.5v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M72 175v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M78 178.5v7l6 3.5v-7z" fill="#213e61" />
  //     <path d="M84 182v7l6 3.5v-7z" fill="#0d7155" />
  //     <path d="M90 185.5v7l6 3.5v-7z" fill="#5c4b2d" />
  //     <path d="M6 143.5v7l6 3.5v-7z" fill="#6c2b06" />
  //     <path d="M12 147v7l6 3.5v-7z" fill="#5f2605" />
  //     <path d="M18 150.5v7l6 3.5v-7z" fill="#4f2004" />
  //     <path d="M24 154v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M30 157.5v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M36 161v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M42 164.5v7l6 3.5v-7z" fill="#2b2110" />
  //     <path d="M48 168v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M54 171.5v7l6 3.5v-7z" fill="#909090" />
  //     <path d="M60 175v7l6 3.5v-7z" fill="#6d6d6d" />
  //     <path d="M66 178.5v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M72 182v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M78 185.5v7l6 3.5v-7z" fill="#213e61" />
  //     <path d="M84 189v7l6 3.5v-7z" fill="#0d7155" />
  //     <path d="M0 147v7l6 3.5v-7z" fill="#907348" />
  //     <path d="M6 150.5v7l6 3.5v-7z" fill="#6c2b06" />
  //     <path d="M12 154v7l6 3.5v-7z" fill="#5f2605" />
  //     <path d="M18 157.5v7l6 3.5v-7z" fill="#4f2004" />
  //     <path d="M24 161v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M30 164.5v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M36 168v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M42 171.5v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M48 175v7l6 3.5v-7z" fill="#2b2110" />
  //     <path d="M54 178.5v7l6 3.5v-7z" fill="#867c08" />
  //     <path d="M60 182v7l6 3.5v-7z" fill="#6d6406" />
  //     <path d="M66 185.5v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M72 189v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M78 192.5v7l6 3.5v-7z" fill="#213e61" />
  //     <path d="M84 196v7l6 3.5v-7z" fill="#0d7155" />
  //     <path d="M90 199.5v7l6 3.5v-7z" fill="#907348" />
  //     <path d="M6 157.5v7l6 3.5v-7z" fill="#6c2b06" />
  //     <path d="M12 161v7l6 3.5v-7z" fill="#5f2605" />
  //     <path d="M18 164.5v7l6 3.5v-7z" fill="#4f2004" />
  //     <path d="M24 168v7l6 3.5v-7z" fill="#961111" />
  //     <path d="M30 171.5v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M36 175v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M42 178.5v7l6 3.5v-7z" fill="#4b5b0a" />
  //     <path d="M48 182v7l6 3.5v-7z" fill="#342714" />
  //     <path d="M54 185.5v7l6 3.5v-7z" fill="#867c08" />
  //     <path d="M60 189v7l6 3.5v-7z" fill="#6d6406" />
  //     <path d="M66 192.5v7l6 3.5v-7z" fill="#740d0d" />
  //     <path d="M72 196v7l6 3.5v-7z" fill="#274871" />
  //     <path d="M78 199.5v7l6 3.5v-7z" fill="#213e61" />
  //     <path d="M84 203v7l6 3.5v-7z" fill="#0d7155" />
  //     <path
  //       d="M0 161v7l6 3.5v-7zM6 164.5v7l6 3.5v-7zM12 168v7l6 3.5v-7z"
  //       fill="#5c4b2d"
  //     />
  //     <path d="M18 171.5v7l6 3.5v-7z" fill="#634e31" />
  //     <path d="M24 175v7l6 3.5v-7zM30 178.5v7l6 3.5v-7z" fill="#544328" />
  //     <path d="M36 182v7l6 3.5v-7z" fill="#634e31" />
  //     <path d="M42 185.5v7l6 3.5v-7z" fill="#544328" />
  //     <path
  //       d="M48 189v7l6 3.5v-7zM54 192.5v7l6 3.5v-7zM60 196v7l6 3.5v-7z"
  //       fill="#5c4b2d"
  //     />
  //     <path d="M66 199.5v7l6 3.5v-7z" fill="#544328" />
  //     <path d="M72 203v7l6 3.5v-7z" fill="#3c301e" />
  //     <path d="M78 206.5v7l6 3.5v-7zM84 210v7l6 3.5v-7z" fill="#544328" />
  //     <path d="M90 213.5v7l6 3.5v-7z" fill="#5c4b2d" />
  //     <g>
  //       <path d="M96 112v112l96-56V56z" fill="#5e4c31" />
  //       <path d="M114 108.5v-7l6-3.5v7z" fill="#5a482d" />
  //       <path
  //         d="M138 94.5v-7l6-3.5v7zM144 91v-7l6-3.5v7zM150 87.5v-7l6-3.5v7z"
  //         fill="#4f4226"
  //       />
  //       <path d="M156 84v-7l6-3.5v7z" fill="#392f1c" />
  //       <path d="M186 66.5v-7l6-3.5v7z" fill="#4f4226" />
  //       <path d="M102 122.5v-7l6-3.5v7z" fill="#161109" />
  //       <path
  //         d="M108 119v-7l6-3.5v7zM114 115.5v-7l6-3.5v7zM120 112v-7l6-3.5v7zM126 108.5v-7l6-3.5v7z"
  //         fill="#130f08"
  //       />
  //       <path d="M132 105v-7l6-3.5v7z" fill="#3a4708" />
  //       <path d="M138 101.5v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M144 98v-7l6-3.5v7zM150 94.5v-7l6-3.5v7z" fill="#161109" />
  //       <path
  //         d="M156 91v-7l6-3.5v7zM162 87.5v-7l6-3.5v7zM168 84v-7l6-3.5v7zM174 80.5v-7l6-3.5v7z"
  //         fill="#130f08"
  //       />
  //       <path d="M180 77v-7l6-3.5v7z" fill="#161109" />
  //       <path d="M186 73.5v-7l6-3.5v7z" fill="#4f4226" />
  //       <path d="M102 129.5v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M108 126v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M114 122.5v-7l6-3.5v7z" fill="#161109" />
  //       <path d="M120 119v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M126 115.5v-7l6-3.5v7z" fill="#15273d" />
  //       <path d="M132 112v-7l6-3.5v7z" fill="#3a4708" />
  //       <path d="M138 108.5v-7l6-3.5v7z" fill="#2f3906" />
  //       <path
  //         d="M144 105v-7l6-3.5v7zM150 101.5v-7l6-3.5v7zM156 98v-7l6-3.5v7z"
  //         fill="#1b150a"
  //       />
  //       <path d="M162 94.5v-7l6-3.5v7z" fill="#3a4708" />
  //       <path d="M168 91v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M174 87.5v-7l6-3.5v7z" fill="#1f170c" />
  //       <path d="M180 84v-7l6-3.5v7z" fill="#20180d" />
  //       <path d="M186 80.5v-7l6-3.5v7z" fill="#4f4226" />
  //       <path d="M96 140v-7l6-3.5v7z" fill="#342a19" />
  //       <path d="M102 136.5v-7l6-3.5v7z" fill="#5a5a5a" />
  //       <path d="M108 133v-7l6-3.5v7z" fill="#444" />
  //       <path d="M114 129.5v-7l6-3.5v7z" fill="#084735" />
  //       <path d="M120 126v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M126 122.5v-7l6-3.5v7z" fill="#15273d" />
  //       <path d="M132 119v-7l6-3.5v7z" fill="#3a4708" />
  //       <path d="M138 115.5v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M144 112v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M150 108.5v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M156 105v-7l6-3.5v7z" fill="#1b150a" />
  //       <path d="M162 101.5v-7l6-3.5v7z" fill="#3a4708" />
  //       <path d="M168 98v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M174 94.5v-7l6-3.5v7z" fill="#084735" />
  //       <path d="M180 91v-7l6-3.5v7z" fill="#063528" />
  //       <path d="M186 87.5v-7l6-3.5v7z" fill="#392f1c" />
  //       <path d="M102 143.5v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M108 140v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M114 136.5v-7l6-3.5v7z" fill="#084735" />
  //       <path d="M120 133v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M126 129.5v-7l6-3.5v7z" fill="#15273d" />
  //       <path d="M132 126v-7l6-3.5v7z" fill="#242424" />
  //       <path d="M138 122.5v-7l6-3.5v7z" fill="#1f1f1f" />
  //       <path d="M144 119v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M150 115.5v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M156 112v-7l6-3.5v7z" fill="#1f170c" />
  //       <path d="M162 108.5v-7l6-3.5v7z" fill="#3a4708" />
  //       <path d="M168 105v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M174 101.5v-7l6-3.5v7z" fill="#5a5a5a" />
  //       <path d="M180 98v-7l6-3.5v7z" fill="#444" />
  //       <path d="M96 154v-7l6-3.5v7z" fill="#4f4226" />
  //       <path d="M102 150.5v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M108 147v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M114 143.5v-7l6-3.5v7z" fill="#084735" />
  //       <path d="M120 140v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M126 136.5v-7l6-3.5v7z" fill="#15273d" />
  //       <path d="M132 133v-7l6-3.5v7z" fill="#242424" />
  //       <path d="M138 129.5v-7l6-3.5v7z" fill="#1f1f1f" />
  //       <path d="M144 126v-7l6-3.5v7z" fill="#20180d" />
  //       <path d="M150 122.5v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M156 119v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M162 115.5v-7l6-3.5v7z" fill="#3a4708" />
  //       <path d="M168 112v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M174 108.5v-7l6-3.5v7z" fill="#084735" />
  //       <path d="M180 105v-7l6-3.5v7z" fill="#063528" />
  //       <path d="M186 101.5v-7l6-3.5v7z" fill="#4f4226" />
  //       <path d="M102 157.5v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M108 154v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M114 150.5v-7l6-3.5v7z" fill="#084735" />
  //       <path d="M120 147v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M126 143.5v-7l6-3.5v7z" fill="#15273d" />
  //       <path d="M132 140v-7l6-3.5v7z" fill="#3a4708" />
  //       <path d="M138 136.5v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M144 133v-7l6-3.5v7z" fill="#1f170c" />
  //       <path d="M150 129.5v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M156 126v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M162 122.5v-7l6-3.5v7z" fill="#3a4708" />
  //       <path d="M168 119v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M174 115.5v-7l6-3.5v7z" fill="#084735" />
  //       <path d="M180 112v-7l6-3.5v7z" fill="#063528" />
  //       <path d="M96 168v-7l6-3.5v7zM102 164.5v-7l6-3.5v7z" fill="#392f1c" />
  //       <path
  //         d="M108 161v-7l6-3.5v7zM114 157.5v-7l6-3.5v7zM120 154v-7l6-3.5v7zM126 150.5v-7l6-3.5v7zM132 147v-7l6-3.5v7z"
  //         fill="#342a19"
  //       />
  //       <path
  //         d="M138 143.5v-7l6-3.5v7zM144 140v-7l6-3.5v7zM150 136.5v-7l6-3.5v7z"
  //         fill="#392f1c"
  //       />
  //       <path
  //         d="M156 133v-7l6-3.5v7zM162 129.5v-7l6-3.5v7zM168 126v-7l6-3.5v7zM174 122.5v-7l6-3.5v7z"
  //         fill="#342a19"
  //       />
  //       <path d="M180 119v-7l6-3.5v7zM186 115.5v-7l6-3.5v7z" fill="#392f1c" />
  //       <path
  //         d="M102 171.5v-7l6-3.5v7zM108 168v-7l6-3.5v7zM114 164.5v-7l6-3.5v7zM120 161v-7l6-3.5v7z"
  //         fill="#4f4226"
  //       />
  //       <path d="M132 154v-7l6-3.5v7zM138 150.5v-7l6-3.5v7z" fill="#5a482d" />
  //       <path
  //         d="M144 147v-7l6-3.5v7zM150 143.5v-7l6-3.5v7zM156 140v-7l6-3.5v7z"
  //         fill="#4f4226"
  //       />
  //       <path d="M162 136.5v-7l6-3.5v7z" fill="#392f1c" />
  //       <path
  //         d="M174 129.5v-7l6-3.5v7zM180 126v-7l6-3.5v7zM186 122.5v-7l6-3.5v7zM96 182v-7l6-3.5v7z"
  //         fill="#4f4226"
  //       />
  //       <path
  //         d="M102 178.5v-7l6-3.5v7zM108 175v-7l6-3.5v7zM114 171.5v-7l6-3.5v7zM120 168v-7l6-3.5v7zM126 164.5v-7l6-3.5v7zM132 161v-7l6-3.5v7z"
  //         fill="#130f08"
  //       />
  //       <path
  //         d="M138 157.5v-7l6-3.5v7zM144 154v-7l6-3.5v7zM150 150.5v-7l6-3.5v7z"
  //         fill="#161109"
  //       />
  //       <path d="M156 147v-7l6-3.5v7zM162 143.5v-7l6-3.5v7z" fill="#130f08" />
  //       <path d="M168 140v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M174 136.5v-7l6-3.5v7z" fill="#15273d" />
  //       <path d="M180 133v-7l6-3.5v7z" fill="#161109" />
  //       <path d="M186 129.5v-7l6-3.5v7z" fill="#4f4226" />
  //       <path d="M102 185.5v-7l6-3.5v7z" fill="#130f08" />
  //       <path d="M108 182v-7l6-3.5v7zM114 178.5v-7l6-3.5v7z" fill="#1b150a" />
  //       <path d="M120 175v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M126 171.5v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M132 168v-7l6-3.5v7zM138 164.5v-7l6-3.5v7z" fill="#1f170c" />
  //       <path d="M144 161v-7l6-3.5v7z" fill="#1b150a" />
  //       <path d="M150 157.5v-7l6-3.5v7z" fill="#544d05" />
  //       <path d="M156 154v-7l6-3.5v7z" fill="#443f04" />
  //       <path d="M162 150.5v-7l6-3.5v7z" fill="#161109" />
  //       <path d="M168 147v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M174 143.5v-7l6-3.5v7z" fill="#15273d" />
  //       <path d="M180 140v-7l6-3.5v7z" fill="#1b150a" />
  //       <path d="M186 136.5v-7l6-3.5v7z" fill="#4f4226" />
  //       <path d="M96 196v-7l6-3.5v7z" fill="#392f1c" />
  //       <path d="M102 192.5v-7l6-3.5v7z" fill="#431b04" />
  //       <path d="M108 189v-7l6-3.5v7z" fill="#3b1803" />
  //       <path d="M114 185.5v-7l6-3.5v7z" fill="#311402" />
  //       <path d="M120 182v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M126 178.5v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M132 175v-7l6-3.5v7z" fill="#1b150a" />
  //       <path d="M138 171.5v-7l6-3.5v7z" fill="#20180d" />
  //       <path d="M144 168v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M150 164.5v-7l6-3.5v7z" fill="#5a5a5a" />
  //       <path d="M156 161v-7l6-3.5v7z" fill="#444" />
  //       <path d="M162 157.5v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M168 154v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M174 150.5v-7l6-3.5v7z" fill="#15273d" />
  //       <path d="M180 147v-7l6-3.5v7z" fill="#084735" />
  //       <path d="M186 143.5v-7l6-3.5v7z" fill="#392f1c" />
  //       <path d="M102 199.5v-7l6-3.5v7z" fill="#431b04" />
  //       <path d="M108 196v-7l6-3.5v7z" fill="#3b1803" />
  //       <path d="M114 192.5v-7l6-3.5v7z" fill="#311402" />
  //       <path d="M120 189v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M126 185.5v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M132 182v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M138 178.5v-7l6-3.5v7z" fill="#1b150a" />
  //       <path d="M144 175v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M150 171.5v-7l6-3.5v7z" fill="#5a5a5a" />
  //       <path d="M156 168v-7l6-3.5v7z" fill="#444" />
  //       <path d="M162 164.5v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M168 161v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M174 157.5v-7l6-3.5v7z" fill="#15273d" />
  //       <path d="M180 154v-7l6-3.5v7z" fill="#084735" />
  //       <path d="M96 210v-7l6-3.5v7z" fill="#5a482d" />
  //       <path d="M102 206.5v-7l6-3.5v7z" fill="#431b04" />
  //       <path d="M108 203v-7l6-3.5v7z" fill="#3b1803" />
  //       <path d="M114 199.5v-7l6-3.5v7z" fill="#311402" />
  //       <path d="M120 196v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M126 192.5v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M132 189v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M138 185.5v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M144 182v-7l6-3.5v7z" fill="#1b150a" />
  //       <path d="M150 178.5v-7l6-3.5v7z" fill="#544d05" />
  //       <path d="M156 175v-7l6-3.5v7z" fill="#443f04" />
  //       <path d="M162 171.5v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M168 168v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M174 164.5v-7l6-3.5v7z" fill="#15273d" />
  //       <path d="M180 161v-7l6-3.5v7z" fill="#084735" />
  //       <path d="M186 157.5v-7l6-3.5v7z" fill="#5a482d" />
  //       <path d="M102 213.5v-7l6-3.5v7z" fill="#431b04" />
  //       <path d="M108 210v-7l6-3.5v7z" fill="#3b1803" />
  //       <path d="M114 206.5v-7l6-3.5v7z" fill="#311402" />
  //       <path d="M120 203v-7l6-3.5v7z" fill="#5e0b0b" />
  //       <path d="M126 199.5v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M132 196v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M138 192.5v-7l6-3.5v7z" fill="#2f3906" />
  //       <path d="M144 189v-7l6-3.5v7z" fill="#20180d" />
  //       <path d="M150 185.5v-7l6-3.5v7z" fill="#544d05" />
  //       <path d="M156 182v-7l6-3.5v7z" fill="#443f04" />
  //       <path d="M162 178.5v-7l6-3.5v7z" fill="#480808" />
  //       <path d="M168 175v-7l6-3.5v7z" fill="#182d47" />
  //       <path d="M174 171.5v-7l6-3.5v7z" fill="#15273d" />
  //       <path d="M180 168v-7l6-3.5v7z" fill="#084735" />
  //       <path
  //         d="M96 224v-7l6-3.5v7zM102 220.5v-7l6-3.5v7zM108 217v-7l6-3.5v7z"
  //         fill="#392f1c"
  //       />
  //       <path d="M114 213.5v-7l6-3.5v7z" fill="#3e311f" />
  //       <path d="M120 210v-7l6-3.5v7zM126 206.5v-7l6-3.5v7z" fill="#342a19" />
  //       <path d="M132 203v-7l6-3.5v7z" fill="#3e311f" />
  //       <path d="M138 199.5v-7l6-3.5v7z" fill="#342a19" />
  //       <path
  //         d="M144 196v-7l6-3.5v7zM150 192.5v-7l6-3.5v7zM156 189v-7l6-3.5v7z"
  //         fill="#392f1c"
  //       />
  //       <path d="M162 185.5v-7l6-3.5v7z" fill="#342a19" />
  //       <path d="M168 182v-7l6-3.5v7z" fill="#261e13" />
  //       <path d="M174 178.5v-7l6-3.5v7zM180 175v-7l6-3.5v7z" fill="#342a19" />
  //       <path d="M186 171.5v-7l6-3.5v7z" fill="#392f1c" />
  //     </g>
  //     <g>
  //       <path d="M0 56l96 56 96-56L96 0z" fill="#bc9862" />
  //       <path d="M96 7l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M102 10.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M108 14l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M114 17.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M132 28l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path
  //         d="M138 31.5l6-3.5 6 3.5-6 3.5zM180 56l6-3.5 6 3.5-6 3.5z"
  //         fill="#9f844d"
  //       />
  //       <path d="M96 14l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M102 17.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M108 21l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M114 24.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M120 28l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M126 31.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M132 35l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M138 38.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M144 42l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M150 45.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M156 49l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M162 52.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path
  //         d="M174 59.5l6-3.5 6 3.5-6 3.5zM90 17.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#9f844d"
  //       />
  //       <path d="M96 21l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M102 24.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M108 28l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M114 31.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M120 35l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M126 38.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M144 49l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M168 63l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M72 14l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M78 17.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M84 21l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M90 24.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M96 28l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M102 31.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M108 35l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M114 38.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M120 42l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M126 45.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M132 49l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M138 52.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M144 56l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M150 59.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M156 63l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M162 66.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M72 21l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M78 24.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M84 28l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path
  //         d="M90 31.5l6-3.5 6 3.5-6 3.5zM108 42l6-3.5 6 3.5-6 3.5zM120 49l6-3.5 6 3.5-6 3.5z"
  //         fill="#9f844d"
  //       />
  //       <path d="M126 52.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M132 56l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M138 59.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M144 63l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M150 66.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M60 21l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M66 24.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M72 28l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M78 31.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M84 35l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M90 38.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M102 45.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M108 49l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M114 52.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M120 56l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M126 59.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M132 63l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M138 66.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M144 70l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M150 73.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M72 35l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M96 49l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M102 52.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M108 56l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path
  //         d="M114 59.5l6-3.5 6 3.5-6 3.5zM48 28l6-3.5 6 3.5-6 3.5z"
  //         fill="#735e39"
  //       />
  //       <path d="M54 31.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M60 35l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M66 38.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M72 42l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M78 45.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M84 49l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M90 52.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M96 56l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M102 59.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M108 63l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M114 66.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M120 70l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M126 73.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M132 77l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path
  //         d="M138 80.5l6-3.5 6 3.5-6 3.5zM72 49l6-3.5 6 3.5-6 3.5z"
  //         fill="#735e39"
  //       />
  //       <path d="M78 52.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M96 63l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M102 66.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M108 70l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path
  //         d="M132 84l6-3.5 6 3.5-6 3.5zM36 35l6-3.5 6 3.5-6 3.5z"
  //         fill="#9f844d"
  //       />
  //       <path d="M42 38.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M48 42l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M54 45.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M60 49l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M72 56l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M78 59.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M84 63l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M90 66.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M96 70l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M102 73.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M114 80.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M120 84l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M126 87.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M36 42l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M42 45.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M48 49l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M66 59.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M72 63l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M78 66.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M84 70l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M90 73.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M96 77l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M102 80.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M108 84l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M114 87.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M120 91l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M24 42l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M30 45.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M36 49l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M42 52.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M48 56l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M54 59.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M60 63l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M66 66.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M72 70l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M78 73.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M84 77l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M90 80.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M96 84l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M102 87.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M108 91l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M114 94.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path
  //         d="M48 63l6-3.5 6 3.5-6 3.5zM60 70l6-3.5 6 3.5-6 3.5z"
  //         fill="#9f844d"
  //       />
  //       <path d="M66 73.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M72 77l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M78 80.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M84 84l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path
  //         d="M90 87.5l6-3.5 6 3.5-6 3.5zM12 49l6-3.5 6 3.5-6 3.5z"
  //         fill="#b4905a"
  //       />
  //       <path d="M18 52.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M24 56l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M54 73.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M66 80.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M72 84l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M78 87.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M84 91l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M90 94.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M96 98l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M102 101.5l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M18 59.5l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path d="M24 63l6-3.5 6 3.5-6 3.5z" fill="#9f844d" />
  //       <path
  //         d="M30 66.5l6-3.5 6 3.5-6 3.5zM48 77l6-3.5 6 3.5-6 3.5zM66 87.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#9f844d"
  //       />
  //       <path d="M72 91l6-3.5 6 3.5-6 3.5z" fill="#b4905a" />
  //       <path d="M0 56l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M6 59.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M12 63l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M18 66.5l6-3.5 6 3.5-6 3.5z" fill="#7c623e" />
  //       <path d="M24 70l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M30 73.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M36 77l6-3.5 6 3.5-6 3.5z" fill="#7c623e" />
  //       <path d="M42 80.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M48 84l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M54 87.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M60 91l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //       <path d="M66 94.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M72 98l6-3.5 6 3.5-6 3.5z" fill="#4c3d26" />
  //       <path d="M78 101.5l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M84 105l6-3.5 6 3.5-6 3.5z" fill="#695433" />
  //       <path d="M90 108.5l6-3.5 6 3.5-6 3.5z" fill="#735e39" />
  //     </g>
  //   </svg>
  // )
}

export default IconBookshelf
