import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

function IconBedrock(props) {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "bedrock.png" }) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.image.childImageSharp.fixed} />

  // return (
  //   <svg baseProfile="full" height="1em" width="1em" viewBox="0 0 192 224" {...props}>
  //     <path d="M0 56v112l96 56V112z" fill="#282828" />
  //     <path d="M0 56v7l6 3.5v-7zM30 73.5v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M36 77v7l6 3.5v-7zM42 80.5v7l6 3.5v-7z" fill="#787878" />
  //     <path d="M48 84v7l6 3.5v-7z" fill="#050505" />
  //     <path d="M54 87.5v7l6 3.5v-7z" fill="#787878" />
  //     <path
  //       d="M60 91v7l6 3.5v-7zM90 108.5v7l6 3.5v-7zM0 63v7l6 3.5v-7zM6 66.5v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path
  //       d="M12 70v7l6 3.5v-7zM18 73.5v7l6 3.5v-7zM24 77v7l6 3.5v-7zM30 80.5v7l6 3.5v-7zM36 84v7l6 3.5v-7zM42 87.5v7l6 3.5v-7zM54 94.5v7l6 3.5v-7zM60 98v7l6 3.5v-7zM66 101.5v7l6 3.5v-7z"
  //       fill="#787878"
  //     />
  //     <path d="M72 105v7l6 3.5v-7z" fill="#050505" />
  //     <path
  //       d="M78 108.5v7l6 3.5v-7zM84 112v7l6 3.5v-7zM0 70v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path d="M6 73.5v7l6 3.5v-7zM24 84v7l6 3.5v-7z" fill="#050505" />
  //     <path d="M48 98v7l6 3.5v-7z" fill="#787878" />
  //     <path
  //       d="M60 105v7l6 3.5v-7zM66 108.5v7l6 3.5v-7zM72 112v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path d="M78 115.5v7l6 3.5v-7z" fill="#787878" />
  //     <path
  //       d="M84 119v7l6 3.5v-7zM90 122.5v7l6 3.5v-7zM0 77v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path d="M6 80.5v7l6 3.5v-7z" fill="#787878" />
  //     <path
  //       d="M12 84v7l6 3.5v-7zM18 87.5v7l6 3.5v-7zM24 91v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path d="M30 94.5v7l6 3.5v-7z" fill="#050505" />
  //     <path d="M36 98v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M42 101.5v7l6 3.5v-7z" fill="#787878" />
  //     <path
  //       d="M48 105v7l6 3.5v-7zM54 108.5v7l6 3.5v-7zM90 129.5v7l6 3.5v-7zM0 84v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path
  //       d="M6 87.5v7l6 3.5v-7zM12 91v7l6 3.5v-7zM60 119v7l6 3.5v-7zM66 122.5v7l6 3.5v-7zM72 126v7l6 3.5v-7zM78 129.5v7l6 3.5v-7zM84 133v7l6 3.5v-7z"
  //       fill="#787878"
  //     />
  //     <path d="M90 136.5v7l6 3.5v-7z" fill="#050505" />
  //     <path
  //       d="M12 98v7l6 3.5v-7zM18 101.5v7l6 3.5v-7zM24 105v7l6 3.5v-7zM48 119v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path d="M54 122.5v7l6 3.5v-7z" fill="#050505" />
  //     <path d="M60 126v7l6 3.5v-7zM66 129.5v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M84 140v7l6 3.5v-7z" fill="#050505" />
  //     <path d="M90 143.5v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M0 98v7l6 3.5v-7zM6 101.5v7l6 3.5v-7z" fill="#787878" />
  //     <path d="M12 105v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M18 108.5v7l6 3.5v-7z" fill="#4f4f4f" />
  //     <path d="M24 112v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M36 119v7l6 3.5v-7zM42 122.5v7l6 3.5v-7z" fill="#787878" />
  //     <path
  //       d="M48 126v7l6 3.5v-7zM54 129.5v7l6 3.5v-7zM60 133v7l6 3.5v-7zM66 136.5v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path
  //       d="M72 140v7l6 3.5v-7zM78 143.5v7l6 3.5v-7zM84 147v7l6 3.5v-7z"
  //       fill="#787878"
  //     />
  //     <path d="M90 150.5v7l6 3.5v-7z" fill="#050505" />
  //     <path
  //       d="M0 105v7l6 3.5v-7zM6 108.5v7l6 3.5v-7zM12 112v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path d="M54 136.5v7l6 3.5v-7z" fill="#050505" />
  //     <path
  //       d="M84 154v7l6 3.5v-7zM90 157.5v7l6 3.5v-7zM0 112v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path d="M6 115.5v7l6 3.5v-7zM12 119v7l6 3.5v-7z" fill="#787878" />
  //     <path d="M18 122.5v7l6 3.5v-7z" fill="#050505" />
  //     <path d="M24 126v7l6 3.5v-7z" fill="#787878" />
  //     <path
  //       d="M36 133v7l6 3.5v-7zM42 136.5v7l6 3.5v-7zM48 140v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path
  //       d="M54 143.5v7l6 3.5v-7zM60 147v7l6 3.5v-7zM66 150.5v7l6 3.5v-7zM72 154v7l6 3.5v-7zM78 157.5v7l6 3.5v-7zM84 161v7l6 3.5v-7zM90 164.5v7l6 3.5v-7z"
  //       fill="#787878"
  //     />
  //     <path d="M0 119v7l6 3.5v-7zM42 143.5v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M48 147v7l6 3.5v-7z" fill="#050505" />
  //     <path d="M54 150.5v7l6 3.5v-7zM60 154v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M84 168v7l6 3.5v-7z" fill="#787878" />
  //     <path
  //       d="M90 171.5v7l6 3.5v-7zM0 126v7l6 3.5v-7zM6 129.5v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path
  //       d="M12 133v7l6 3.5v-7zM30 143.5v7l6 3.5v-7zM36 147v7l6 3.5v-7z"
  //       fill="#787878"
  //     />
  //     <path d="M48 154v7l6 3.5v-7z" fill="#050505" />
  //     <path
  //       d="M60 161v7l6 3.5v-7zM66 164.5v7l6 3.5v-7zM72 168v7l6 3.5v-7z"
  //       fill="#787878"
  //     />
  //     <path
  //       d="M78 171.5v7l6 3.5v-7zM84 175v7l6 3.5v-7zM90 178.5v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path d="M0 133v7l6 3.5v-7z" fill="#050505" />
  //     <path
  //       d="M6 136.5v7l6 3.5v-7zM12 140v7l6 3.5v-7zM18 143.5v7l6 3.5v-7zM30 150.5v7l6 3.5v-7zM36 154v7l6 3.5v-7zM42 157.5v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path d="M48 161v7l6 3.5v-7zM54 164.5v7l6 3.5v-7z" fill="#787878" />
  //     <path
  //       d="M60 168v7l6 3.5v-7zM66 171.5v7l6 3.5v-7zM72 175v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path d="M78 178.5v7l6 3.5v-7zM84 182v7l6 3.5v-7z" fill="#787878" />
  //     <path d="M90 185.5v7l6 3.5v-7z" fill="#050505" />
  //     <path d="M0 140v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M48 168v7l6 3.5v-7zM54 171.5v7l6 3.5v-7z" fill="#787878" />
  //     <path d="M90 192.5v7l6 3.5v-7zM0 147v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M6 150.5v7l6 3.5v-7zM18 157.5v7l6 3.5v-7z" fill="#787878" />
  //     <path d="M24 161v7l6 3.5v-7z" fill="#050505" />
  //     <path d="M30 164.5v7l6 3.5v-7zM36 168v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M60 182v7l6 3.5v-7z" fill="#050505" />
  //     <path d="M72 189v7l6 3.5v-7zM78 192.5v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M84 196v7l6 3.5v-7zM90 199.5v7l6 3.5v-7z" fill="#787878" />
  //     <path
  //       d="M0 154v7l6 3.5v-7zM6 157.5v7l6 3.5v-7zM12 161v7l6 3.5v-7zM48 182v7l6 3.5v-7zM54 185.5v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <path d="M60 189v7l6 3.5v-7zM66 192.5v7l6 3.5v-7z" fill="#787878" />
  //     <path d="M72 196v7l6 3.5v-7zM90 206.5v7l6 3.5v-7z" fill="#454545" />
  //     <path d="M24 175v7l6 3.5v-7z" fill="#050505" />
  //     <path d="M30 178.5v7l6 3.5v-7zM36 182v7l6 3.5v-7z" fill="#787878" />
  //     <path
  //       d="M42 185.5v7l6 3.5v-7zM48 189v7l6 3.5v-7zM54 192.5v7l6 3.5v-7zM60 196v7l6 3.5v-7zM84 210v7l6 3.5v-7zM90 213.5v7l6 3.5v-7z"
  //       fill="#454545"
  //     />
  //     <g>
  //       <path d="M96 112v112l96-56V56z" fill="#191919" />
  //       <path d="M96 119v-7l6-3.5v7zM126 101.5v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M132 98v-7l6-3.5v7zM138 94.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path d="M144 91v-7l6-3.5v7z" fill="#030303" />
  //       <path d="M150 87.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path
  //         d="M156 84v-7l6-3.5v7zM186 66.5v-7l6-3.5v7zM96 126v-7l6-3.5v7zM102 122.5v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path
  //         d="M108 119v-7l6-3.5v7zM114 115.5v-7l6-3.5v7zM120 112v-7l6-3.5v7zM126 108.5v-7l6-3.5v7zM132 105v-7l6-3.5v7zM138 101.5v-7l6-3.5v7zM150 94.5v-7l6-3.5v7zM156 91v-7l6-3.5v7zM162 87.5v-7l6-3.5v7z"
  //         fill="#4b4b4b"
  //       />
  //       <path d="M168 84v-7l6-3.5v7z" fill="#030303" />
  //       <path
  //         d="M174 80.5v-7l6-3.5v7zM180 77v-7l6-3.5v7zM96 133v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path d="M102 129.5v-7l6-3.5v7zM120 119v-7l6-3.5v7z" fill="#030303" />
  //       <path d="M144 105v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path
  //         d="M156 98v-7l6-3.5v7zM162 94.5v-7l6-3.5v7zM168 91v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path d="M174 87.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path
  //         d="M180 84v-7l6-3.5v7zM186 80.5v-7l6-3.5v7zM96 140v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path d="M102 136.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path
  //         d="M108 133v-7l6-3.5v7zM114 129.5v-7l6-3.5v7zM120 126v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path d="M126 122.5v-7l6-3.5v7z" fill="#030303" />
  //       <path d="M132 119v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M138 115.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path
  //         d="M144 112v-7l6-3.5v7zM150 108.5v-7l6-3.5v7zM186 87.5v-7l6-3.5v7zM96 147v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path
  //         d="M102 143.5v-7l6-3.5v7zM108 140v-7l6-3.5v7zM156 112v-7l6-3.5v7zM162 108.5v-7l6-3.5v7zM168 105v-7l6-3.5v7zM174 101.5v-7l6-3.5v7zM180 98v-7l6-3.5v7z"
  //         fill="#4b4b4b"
  //       />
  //       <path d="M186 94.5v-7l6-3.5v7z" fill="#030303" />
  //       <path
  //         d="M108 147v-7l6-3.5v7zM114 143.5v-7l6-3.5v7zM120 140v-7l6-3.5v7zM144 126v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path d="M150 122.5v-7l6-3.5v7z" fill="#030303" />
  //       <path d="M156 119v-7l6-3.5v7zM162 115.5v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M180 105v-7l6-3.5v7z" fill="#030303" />
  //       <path d="M186 101.5v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M96 161v-7l6-3.5v7zM102 157.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path d="M108 154v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M114 150.5v-7l6-3.5v7z" fill="#313131" />
  //       <path d="M120 147v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M132 140v-7l6-3.5v7zM138 136.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path
  //         d="M144 133v-7l6-3.5v7zM150 129.5v-7l6-3.5v7zM156 126v-7l6-3.5v7zM162 122.5v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path
  //         d="M168 119v-7l6-3.5v7zM174 115.5v-7l6-3.5v7zM180 112v-7l6-3.5v7z"
  //         fill="#4b4b4b"
  //       />
  //       <path d="M186 108.5v-7l6-3.5v7z" fill="#030303" />
  //       <path
  //         d="M96 168v-7l6-3.5v7zM102 164.5v-7l6-3.5v7zM108 161v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path d="M150 136.5v-7l6-3.5v7z" fill="#030303" />
  //       <path
  //         d="M180 119v-7l6-3.5v7zM186 115.5v-7l6-3.5v7zM96 175v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path d="M102 171.5v-7l6-3.5v7zM108 168v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path d="M114 164.5v-7l6-3.5v7z" fill="#030303" />
  //       <path d="M120 161v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path
  //         d="M132 154v-7l6-3.5v7zM138 150.5v-7l6-3.5v7zM144 147v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path
  //         d="M150 143.5v-7l6-3.5v7zM156 140v-7l6-3.5v7zM162 136.5v-7l6-3.5v7zM168 133v-7l6-3.5v7zM174 129.5v-7l6-3.5v7zM180 126v-7l6-3.5v7zM186 122.5v-7l6-3.5v7z"
  //         fill="#4b4b4b"
  //       />
  //       <path d="M96 182v-7l6-3.5v7zM138 157.5v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M144 154v-7l6-3.5v7z" fill="#030303" />
  //       <path d="M150 150.5v-7l6-3.5v7zM156 147v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M180 133v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path
  //         d="M186 129.5v-7l6-3.5v7zM96 189v-7l6-3.5v7zM102 185.5v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path
  //         d="M108 182v-7l6-3.5v7zM126 171.5v-7l6-3.5v7zM132 168v-7l6-3.5v7z"
  //         fill="#4b4b4b"
  //       />
  //       <path d="M144 161v-7l6-3.5v7z" fill="#030303" />
  //       <path
  //         d="M156 154v-7l6-3.5v7zM162 150.5v-7l6-3.5v7zM168 147v-7l6-3.5v7z"
  //         fill="#4b4b4b"
  //       />
  //       <path
  //         d="M174 143.5v-7l6-3.5v7zM180 140v-7l6-3.5v7zM186 136.5v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path d="M96 196v-7l6-3.5v7z" fill="#030303" />
  //       <path
  //         d="M102 192.5v-7l6-3.5v7zM108 189v-7l6-3.5v7zM114 185.5v-7l6-3.5v7zM126 178.5v-7l6-3.5v7zM132 175v-7l6-3.5v7zM138 171.5v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path d="M144 168v-7l6-3.5v7zM150 164.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path
  //         d="M156 161v-7l6-3.5v7zM162 157.5v-7l6-3.5v7zM168 154v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path d="M174 150.5v-7l6-3.5v7zM180 147v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path d="M186 143.5v-7l6-3.5v7z" fill="#030303" />
  //       <path d="M96 203v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M144 175v-7l6-3.5v7zM150 171.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path d="M186 150.5v-7l6-3.5v7zM96 210v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M102 206.5v-7l6-3.5v7zM114 199.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path d="M120 196v-7l6-3.5v7z" fill="#030303" />
  //       <path d="M126 192.5v-7l6-3.5v7zM132 189v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M156 175v-7l6-3.5v7z" fill="#030303" />
  //       <path d="M168 168v-7l6-3.5v7zM174 164.5v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M180 161v-7l6-3.5v7zM186 157.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path
  //         d="M96 217v-7l6-3.5v7zM102 213.5v-7l6-3.5v7zM108 210v-7l6-3.5v7zM144 189v-7l6-3.5v7zM150 185.5v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //       <path d="M156 182v-7l6-3.5v7zM162 178.5v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path d="M168 175v-7l6-3.5v7zM186 164.5v-7l6-3.5v7z" fill="#2b2b2b" />
  //       <path d="M120 210v-7l6-3.5v7z" fill="#030303" />
  //       <path d="M126 206.5v-7l6-3.5v7zM132 203v-7l6-3.5v7z" fill="#4b4b4b" />
  //       <path
  //         d="M138 199.5v-7l6-3.5v7zM144 196v-7l6-3.5v7zM150 192.5v-7l6-3.5v7zM156 189v-7l6-3.5v7zM180 175v-7l6-3.5v7zM186 171.5v-7l6-3.5v7z"
  //         fill="#2b2b2b"
  //       />
  //     </g>
  //     <g>
  //       <path d="M0 56l96 56 96-56L96 0z" fill="#333" />
  //       <path
  //         d="M90 3.5L96 0l6 3.5L96 7zM120 21l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M126 24.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M132 28l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M138 31.5l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M144 35l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path
  //         d="M150 38.5l6-3.5 6 3.5-6 3.5zM180 56l6-3.5 6 3.5-6 3.5zM84 7l6-3.5L96 7l-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M90 10.5L96 7l6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M96 14l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M102 17.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M108 21l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M114 24.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M120 28l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path
  //         d="M126 31.5l6-3.5 6 3.5-6 3.5zM138 38.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#979797"
  //       />
  //       <path d="M144 42l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M150 45.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M156 49l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M162 52.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path
  //         d="M168 56l6-3.5 6 3.5-6 3.5zM78 10.5L84 7l6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path
  //         d="M84 14l6-3.5 6 3.5-6 3.5zM102 24.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#070707"
  //       />
  //       <path d="M126 38.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M138 45.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M144 49l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M150 52.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M156 56l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M162 59.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path
  //         d="M168 63l6-3.5 6 3.5-6 3.5zM72 14l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M78 17.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M84 21l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M90 24.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M96 28l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M102 31.5l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M108 35l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M114 38.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M120 42l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path
  //         d="M126 45.5l6-3.5 6 3.5-6 3.5zM162 66.5l6-3.5 6 3.5-6 3.5zM66 17.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M72 21l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path
  //         d="M78 24.5l6-3.5 6 3.5-6 3.5zM126 52.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#979797"
  //       />
  //       <path d="M132 56l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M138 59.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M144 63l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M150 66.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M156 70l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M72 28l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M78 31.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path
  //         d="M84 35l6-3.5 6 3.5-6 3.5zM108 49l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M114 52.5l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M120 56l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M126 59.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M144 70l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M150 73.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M54 24.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M60 28l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M66 31.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M72 35l6-3.5 6 3.5-6 3.5z" fill="#636363" />
  //       <path d="M78 38.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M90 45.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M96 49l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M102 52.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M108 56l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M114 59.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M120 63l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M126 66.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M132 70l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M138 73.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M144 77l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M48 28l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M54 31.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M60 35l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M102 59.5l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M132 77l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path
  //         d="M138 80.5l6-3.5 6 3.5-6 3.5zM42 31.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M48 35l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M54 38.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M60 42l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M66 45.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M78 52.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M84 56l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M90 59.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M96 63l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M102 66.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M108 70l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M114 73.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M120 77l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M126 80.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M132 84l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path
  //         d="M36 35l6-3.5 6 3.5-6 3.5zM78 59.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M84 63l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M90 66.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M96 70l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M120 84l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path
  //         d="M126 87.5l6-3.5 6 3.5-6 3.5zM30 38.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M36 42l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path
  //         d="M42 45.5l6-3.5 6 3.5-6 3.5zM60 56l6-3.5 6 3.5-6 3.5z"
  //         fill="#979797"
  //       />
  //       <path d="M66 59.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M78 66.5l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M90 73.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M96 77l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M102 80.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M108 84l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M114 87.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M120 91l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M24 42l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M30 45.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M36 49l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path
  //         d="M42 52.5l6-3.5 6 3.5-6 3.5zM54 59.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M60 63l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M66 66.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M72 70l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M78 73.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M84 77l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M90 80.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M96 84l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M102 87.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M108 91l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M114 94.5l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M18 45.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M66 73.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M72 77l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path
  //         d="M108 98l6-3.5 6 3.5-6 3.5zM12 49l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path
  //         d="M18 52.5l6-3.5 6 3.5-6 3.5zM30 59.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#979797"
  //       />
  //       <path d="M36 63l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M42 66.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M48 70l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M72 84l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M84 91l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M90 94.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M96 98l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M102 101.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M6 52.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M12 56l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path
  //         d="M18 59.5l6-3.5 6 3.5-6 3.5zM54 80.5l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M60 84l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M66 87.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M72 91l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path
  //         d="M78 94.5l6-3.5 6 3.5-6 3.5zM96 105l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M24 70l6-3.5 6 3.5-6 3.5z" fill="#070707" />
  //       <path d="M30 73.5l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M36 77l6-3.5 6 3.5-6 3.5z" fill="#979797" />
  //       <path d="M42 80.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M48 84l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path d="M54 87.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //       <path
  //         d="M60 91l6-3.5 6 3.5-6 3.5zM84 105l6-3.5 6 3.5-6 3.5z"
  //         fill="#575757"
  //       />
  //       <path d="M90 108.5l6-3.5 6 3.5-6 3.5z" fill="#575757" />
  //     </g>
  //   </svg>
  // )
}

export default IconBedrock
