import * as React from "react"
import IconJava from "./iconJava"
import IconBedrock from "./iconBedrock"
import IconBookshelf from "./iconBookshelf"

function PlatformSelection({ platform, onChangePlatform, available }) {
  return (
    <div className="flex flex-col md:flex-row my-8 mb-16">
      {(!available || available.java) && <label className="flex text-6xl items-center md:mr-8">
        <IconJava />
        <input name="platform" value="java" checked={platform === 'java'} onChange={() => { onChangePlatform('java'); }} type="radio" className="ml-2" />
        <span className="text-xl ml-2">Java Edition</span>
      </label>}
      {(!available || available.bedrock) && <label className="flex text-6xl items-center md:mr-8">
        <IconBedrock />
        <input name="platform" value="bedrock" checked={platform === 'bedrock'} onChange={() => { onChangePlatform('bedrock'); }} type="radio" className="ml-2" />
        <span className="text-xl ml-2">Bedrock Edition</span>
      </label>}
      {(!available || available.education) && <label className="flex text-6xl items-center md:mr-8">
        <IconBookshelf />
        <input name="platform" value="education" checked={platform === 'education'} onChange={() => { onChangePlatform('education'); }} type="radio" className="ml-2" />
        <span className="text-xl ml-2">Education Edition</span>
      </label>}
    </div>
  );
}

export default PlatformSelection